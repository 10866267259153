import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { imageMediaThaiLan } from 'constants/data';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { PROXY_IMAGE } from 'sysconfig';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { getLinkCacheProxy, getLinkProxy } from 'utils/ImageUtils';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

const MEDIA_PATH = `${PROXY_IMAGE}/thuocsi-live/web/buymed-th/static/images/logo-partner/`;

const renderMediaItemImage = (data, lang = 'th', isMobile = false) =>
  data.map((item) => (
    <Grid
      key={`media-${uuidv4()}`}
      className={clsx(!isMobile ? styles.hover_link : styles.mobile_hover_link)}
      style={{
        width: !isMobile ? '339px' : '343px',
      }}
    >
      <a
        href={item.href}
        target="_blank"
        rel="noreferrer"
        style={{
          display: 'block',
          width: '100%',
          height: !isMobile ? '172px' : '159px',
        }}
      >
        <ImageFallbackStatic
          className={item.dark && styles.dark}
          src={getLinkCacheProxy({ url: `${MEDIA_PATH}${item[!isMobile ? `url_${lang}` : `url_mobile_${lang}`]}`, w: 1500 })}
          // fallbackSrc={`${MEDIA_PATH}${item[!isMobile ? `url_${lang}` : `url_mobile_${lang}`]}`}
          fallbackSrc={getLinkProxy(`${MEDIA_PATH}${item[!isMobile ? `url_${lang}` : `url_mobile_${lang}`]}`)}
          layout="fill"
          objectFit="contain"
        />
      </a>
    </Grid>
  ));

const Media = ({ t, lang, isMobile = false }) => {
  let settings = {
    className: 'variable-width',
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <></>,
    prevArrow: <></>,
  };
  if (isMobile) {
    settings = {
      ...settings,
      vertical: true,
      verticalSwiping: true,
      variableWidth: false,
      swipeToSlide: true,
    };
  }
  return (
    <>
      <div className={clsx(!isMobile ? styles.media_wrapper : styles.mobile_media_wrapper)}>
        <Typography variant="h4" align="center" className={clsx(!isMobile ? styles.media_title : styles.mobile_media_title)}>
          {t('landingPage:what_media_says_about_thuocsi')}
        </Typography>

        <Grid container justifyContent="center" alignItems="center">
          <Slider
            {...settings}
            className={styles.slider}
            style={{
              height: !isMobile && '200px',
            }}
          >
            {renderMediaItemImage(imageMediaThaiLan, lang, isMobile)}
          </Slider>
        </Grid>
      </div>
      {/* <div className={clsx(!isMobile ? styles.media_wrapper : styles.mobile_media_wrapper)}>
        <Typography variant="h4" align="center" className={clsx(!isMobile ? styles.media_title : styles.mobile_media_title)}>
          {t('landingPage:our_partners')}
        </Typography>
        <div className={clsx(!isMobile ? styles.image_wrapper : styles.mobile_image_wrapper)}>
          <ImageFallbackStoreImage
            src={isMobile ? getLinkCacheProxy({ url: MOBILE_PARTNER, w: 500 }) : getLinkCacheProxy({ url: PARTNER, w: 1500 })}
            fallbackSrc={isMobile ? getLinkProxy(MOBILE_PARTNER) : getLinkProxy(PARTNER)}
            layout="fill"
            objectFit="cover"
          />
        </div>
      </div> */}
    </>
  );
};

export default Media;

import { ArrowRight } from '@material-ui/icons';
import { MOBILE_REGISTER_BANNER, REGISTER_BANNER } from 'constants/Images/imageStaticCloud';
import { GUIDE_URL } from 'constants/Paths';
import { memo } from 'react';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { getLinkCacheProxy, getLinkProxy } from 'utils/ImageUtils';
import styles from './styles.module.css';

const HomeCTASection = ({ t, isMobile = false }) => {
  if (isMobile) {
    return (
      <div className={styles.mobileRegisterBanner}>
        <p className={styles.mobileRgisterText}>{t('common:homeCTAsection.register')}</p>
        <ImageFallbackStatic
          width={515}
          height={150}
          src={getLinkCacheProxy({ url: MOBILE_REGISTER_BANNER, w: 500 })}
          fallbackSrc={getLinkProxy(MOBILE_REGISTER_BANNER)}
          alt="register"
        />
        <a className={styles.mobileWatchTutorialButton} href={GUIDE_URL}>
          <span>{t('common:homeCTAsection.watch_tutorial')}</span> <ArrowRight />
        </a>
      </div>
    );
  }
  return (
    <div className={styles.registerBanner}>
      <p className={styles.registerText}>{t('common:homeCTAsection.register')}</p>
      <ImageFallbackStatic
        width={600}
        height={520}
        src={getLinkCacheProxy({ url: REGISTER_BANNER, w: 500 })}
        fallbackSrc={getLinkProxy(REGISTER_BANNER)}
        alt="register"
      />
      <a className={styles.watchTutorialButton} href={GUIDE_URL}>
        <span>{t('common:homeCTAsection.watch_tutorial')}</span> <ArrowRight />
      </a>
    </div>
  );
};

export default memo(HomeCTASection);

import { BANNER_DISPLAY_SYSTEM, BANNER_TYPE } from 'constants/Banner';
import { settingsSliderBanner } from 'constants/data';
import { useDragDetection } from 'hooks';
import { memo, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { gtag } from 'utils';
import { ImageFallbackBanner } from 'utils/ImageFallback';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

// tracking là banner trang chủ
const checkCondition = ({ targetURL: link, imgURL: image, name: alt, isTracking = true, width = 2500, isMobile = false }) => {
  const ItemBox = (
    <div className={styles.banner_bg_img}>
      <ImageFallbackBanner
        src={image}
        fallbackSrc={image}
        layout="fill"
        quality={100}
        width={width}
        isUseLoaderCacheProxy
        style={{ borderRadius: 12 }}
      />
    </div>
  );
  if (link && link.length > 0) {
    return (
      <a
        alt={alt}
        title={alt}
        href={link}
        onClick={() => {
          if (isTracking) gtag.clickBanner({ link });
        }}
        style={{
          display: 'block',
        }}
      >
        {ItemBox}
      </a>
    );
  }
  return ItemBox;
};

// in-use
const BannerSlider = ({ banners = [], isSeller = false, isMobile = false, isCheckType = true }) => {
  const isTracking = !isSeller && true;

  const homeBanners = useStore((state) => state.banners);
  const infoBanner = banners?.length > 0 ? banners : homeBanners;

  const { handleMouseDown, dragging } = useDragDetection();
  const ref = useRef({});
  const handleChildClick = (e) => {
    if (dragging) {
      e.preventDefault();
    }
  };
  let sliderItem = [];
  if (isCheckType) {
    sliderItem = infoBanner
      ?.filter(
        (item) =>
          item?.type?.includes(BANNER_TYPE.HOMEPAGE_FULL_BANNER) &&
          (!isMobile ? item?.displaySystem?.includes(BANNER_DISPLAY_SYSTEM.WEB) : item?.displaySystem?.includes(BANNER_DISPLAY_SYSTEM.WEB_MOBILE)),
      )
      ?.map((item) => {
        let itemSeller = {};
        if (isSeller) {
          itemSeller = {
            targetURL: item?.link,
            imgURL: item?.images[0],
            name: '',
            isTracking,
          };
        }

        const itemSlider = checkCondition(isSeller ? { ...itemSeller, isMobile } : { ...item, isMobile });
        return (
          <div key={`slider-${item.id || item.code}`} onMouseDownCapture={handleMouseDown} onClickCapture={handleChildClick}>
            {itemSlider}
          </div>
        );
      });
  } else {
    sliderItem = infoBanner?.map((item) => {
      let itemSeller = {};
      if (isSeller) {
        itemSeller = {
          targetURL: item?.link,
          imgURL: item?.images[0],
          name: '',
          isTracking,
        };
      }

      const itemSlider = checkCondition(isSeller ? { ...itemSeller, isMobile } : { ...item, isMobile });
      return (
        <div key={`slider-${item.id || item.code}`} onMouseDownCapture={handleMouseDown} onClickCapture={handleChildClick}>
          {itemSlider}
        </div>
      );
    });
  }

  return (
    <Slider
      ref={ref}
      {...settingsSliderBanner}
      afterChange={(index) => {
        if (isTracking) {
          gtag.showBanner({ link: infoBanner[index]?.targetURL || '' });
        }
      }}
      style={{ margin: !isMobile ? '16px 0px 0px' : '8px 0px' }}
    >
      {sliderItem}
    </Slider>
  );
};

export default memo(BannerSlider);

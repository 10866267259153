import clsx from 'clsx';
import { ImageFallbackStoreImage } from 'utils/ImageFallback';
import styles from './styles.module.css';

const Banner = ({ t, lang, isMobile = false }) => (
  <>
    <div className={clsx(!isMobile ? styles.media_wrapper : styles.mobile_media_wrapper)}>
      <div className={clsx(!isMobile ? styles.banner_image_wrapper : styles.banner_mobile_image_wrapper)}>
        <ImageFallbackStoreImage src="/images/banner/030924_infobanner.png" layout="fill" objectFit="cover" style={{ borderRadius: '8px' }} />
      </div>
    </div>
  </>
);

export default Banner;

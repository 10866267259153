export const BANNER_TYPE = {
    HOMEPAGE_FULL_BANNER: "HOMEPAGE_FULL_BANNER",
    HOMEPAGE_MINI_BANNER: "HOMEPAGE_MINI_BANNER"
  }
  
  export const BANNER_DISPLAY_SYSTEM = {
    WEB: "WEB",
    WEB_MOBILE: "WEB_MOBILE",
    APP: "APP" 
  }
  export default {
    BANNER_TYPE,
    BANNER_DISPLAY_SYSTEM
  }
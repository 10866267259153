import { Grid } from '@material-ui/core';
import { MarketingClient } from 'clients';
import Template from 'components/layout/Template';
import AboutUs from 'components/organisms/AboutUs';
import BannerSlider from 'components/organisms/BannerSlider';
import HomeCTASection from 'components/organisms/HomeCTASection';
import Media from 'components/organisms/Media';
import Banner from 'components/organisms/Media/banner';
import WhyBuymed from 'components/organisms/WhyBuymed';
import { GUEST_HOMEPAGE_BACKGROUND } from 'constants/Images/imageStaticCloud';
import { HOME_PAGE } from 'constants/Paths';
import { DEFAULT_THUOCSI_LONG_TITLE } from 'constants/data';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { isMobile as isMobileDetect, isTablet } from 'react-device-detect';
import { ACCESS_TOKEN, LANG_DEFAULT } from 'sysconfig';
import CookieParser from 'utils/CookieParser';
import { getLinkCacheProxy } from 'utils/ImageUtils';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

const title = DEFAULT_THUOCSI_LONG_TITLE;

export async function getStaticProps(ctx) {
  const lang = CookieParser.getCookieFromCtx(ctx, 'NEXT_LOCALE') || LANG_DEFAULT;
  ctx.locale = lang;
  const i18next = {};
  // i18next = await serverSideTranslations(lang, ['landingPage', 'terms-and-conditions', 'conversations', 'common', 'popup-action'], {
  //   i18n: { defaultLocale: lang },
  // });
  return {
    props: {
      user: null,
      lang,
      ...i18next,
      SEO_CONFIG: {
        title,
      },
    },
    revalidate: 15 * 60, // 15 minutes
  };
}

const pageName = 'home';
const pageTitle = 'Homepage';

const GuestHomePage = (props) => {
  const [banners, setBanners] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const getBanners = async () => {
    const bannerResp = await MarketingClient.getListBanner();
    if (bannerResp.status === 'OK') {
      const bannerList = bannerResp.data || [];
      setBanners(bannerList);
    }
  };
  const { lang } = props;
  const toggleFeature = useStore((state) => state.toggleFeature);
  const router = useRouter();

  useEffect(() => {
    const accessToken = Cookies.get(ACCESS_TOKEN);
    if (accessToken) {
      router.push(HOME_PAGE);
    }
    setIsMobile(isMobileDetect && !isTablet);
    getBanners();
  }, []);
  const { chatTs } = router.query;
  const { t: translateLandingPage } = useTranslation('landingPage');

  useEffect(() => {
    if (chatTs != null) toggleFeature('chatTs', chatTs, { isCookie: true });
  }, [chatTs]);

  return (
    <Template isMobile={isMobile} pageName={pageName} pageTitle={pageTitle} chatTs={chatTs}>
      <div className={styles.gradient}>
        <Grid
          container
          justifyContent="center"
          className={styles.background}
          style={{ backgroundImage: `url(${getLinkCacheProxy({ url: GUEST_HOMEPAGE_BACKGROUND, w: 1500, q: 70 })})` }}
        >
          <div className={styles.container}>
            <div className={isMobile ? styles.mobileBannerGroup : styles.bannerGroup}>
              <div className={isMobile ? styles.mobileSliderBanner : styles.sliderBanner}>
                <BannerSlider banners={banners} isMobile={isMobile} />
              </div>
              <div className={isMobile ? styles.mobileCtaWrapper : styles.ctaWrapper}>
                <HomeCTASection t={translateLandingPage} isMobile={isMobile} />
              </div>
            </div>

            <WhyBuymed t={translateLandingPage} isMobile={isMobile} />
            <Banner t={translateLandingPage} lang={lang} isMobile={isMobile} />

            <AboutUs t={translateLandingPage} isMobile={isMobile} />
            <Media t={translateLandingPage} lang={lang} isMobile={isMobile} />
          </div>
        </Grid>
      </div>
    </Template>
  );
};

export default GuestHomePage;

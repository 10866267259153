// homepage
export const REGISTER_BANNER = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/register_banner.png';
export const MOBILE_REGISTER_BANNER = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/mobile_register_banner.png';

export const QUALITY_PRODUCT = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/quality_product.png';
export const SUPPORT = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/guest_homepage_support.png';
export const FAST_DELIVERY = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/fast_delivery.png';
export const COMPREHENSIVE_RANGE = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/comprehensive_range.png';
export const GUEST_HOMEPAGE_BACKGROUND = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/guest_homepage_background.png';
export const PARTNER = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/static/images/logo-partner/our_partners_1.png';
export const MOBILE_PARTNER = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/static/images/logo-partner/our_partners_m_1.png';

export const CONNECTION = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/about_us/Connection.png';
export const MOBILE_CONNECTION = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/about_us/Connection_m.png';
export const VISION = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/about_us/Vision.png';
export const MOBILE_VISION = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/about_us/Vision_m.png';
export const MISSION = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/about_us/Mission.png';
export const MOBILE_MISSION = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/about_us/Mission_m.png';

export const CATEGORIES_BACKGROUND = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/static/images/homepage/background_categories.png';
export const MOBILE_CATEGORIES_BACKGROUND =
  'https://storage.googleapis.com/thuocsi-live/web/buymed-th/static/images/homepage/background_categories_mobile.png';


// about us
export const ABOUT_US_PART_1 = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/about_us/ab-1.jpg';
export const ABOUT_US_PART_2 = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/about_us/ab-2.jpg';
export const ABOUT_US_PART_3 = 'https://storage.googleapis.com/thuocsi-live/web/buymed-th/images/about_us/ab-3.jpg';
